.drawer-screen {
    background-color: var(--background-color);
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
}

.drawer-screen-content {
    display: flex;
    flex: 1;
    height: 100%;
    transition: all 0.3s ease;
    background-color: white;
}