.report-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .report-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 40%;
    max-height: 90vh;
    overflow-y: auto;
  }
  
  .report-sheet-parent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .report-sheet-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .header-text {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    color: #333;
  }
  
  .report-close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #666;
  }
  
  .report-types-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .report-type {
    padding: 8px 15px;
    border: 1px solid #ccc;
    border-radius: 20px;
    background: none;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.3s ease;
  }
  
  .report-type:hover {
    background-color: #f0f0f0;
  }
  
  .report-type.selected {
    background-color: #FF4A00;
    color: white;
    border-color: "rgba(255, 74, 0, 1)";
  }
  
  .report-textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    resize: vertical;
    border-radius: 20px;
    transition: all 0.3s ease;
  }
  
  .report-textarea:focus {
    outline: none;
    border-color: #FF4A00;
  }
  
  .report-textarea.has-content {
    border-color: "rgba(255, 74, 0, 1)";
    background-color: white;
  }
  
  .submit-button {
    padding: 10px 20px;
    background-color: #FF4A00 ;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 30px;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #FF4A00;
  }
  
  .submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .success-message {
    margin-top: 20px;
    padding: 15px;
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
    border-radius: 5px;
    color: #155724;
  }
  
  .success-message h3 {
    margin-top: 0;
    font-size: 18px;
  }
  
  .success-message p {
    margin-bottom: 0;
  }
  