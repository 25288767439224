
.profile-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
    width: 100%;
    margin-left: 70px;
}

.data-section {
    width: 50%;

}

.data-title {
    /* font-size: 1.5rem; */
    color: rgb(122, 122, 122);
    margin-bottom: 10px;
    font-weight: bold;
    color : var(--primary-color);
    margin-top: 30px;
}

.data-label {
    font-size: 1.2rem;
    color: rgb(31, 31, 31);
    /* font-weight: 600; */
}

.data-input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: none;
    /* margin-bottom: 20px; */
    background-color: #f0f0f0;
    font-size: 1rem;
}

.edit{
    background-color: white;
 
    color: #000;
    /* padding: 10px 20px; */
    /* border-radius: 50px; */
    border: none;
    cursor: pointer;
    /* font-size: 1rem; */
    /* margin:10px; */
    margin-right: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #000;

}