.login-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--background-color);
}

.wrapper {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.login-logo {
    width: 250px;
    margin-bottom: 20px;
}

.input-container {
    width: 100%;
    margin-bottom: 20px;
}

.login-input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 18px;
    font-size: 16px;
    margin: 0;
}

.error-message {
    color: #ff4444;
    font-size: 14px;
    margin-top: 4px;
    display: block;
    text-align: left;
}

.login-button {
    width: 71% !important;
    margin-bottom: 16px;
}

.divider {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #ccc;
    line-height: 0.1em;
    margin: 15px 0;
}

.divider span {
    background: var(--background-color);
    padding: 0 10px;
    color: #666;
}

.social-login {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 45px;
    transform: scale(1.2);
    margin-right: 76px;
}

#googleButton,
iframe[role="presentation"],
div[class*="google-login"],
div[style*="position: relative"] {
    width: 100% !important;
    border-radius: 24px !important;
    padding: 20px 32px !important;
    font-size: 18px !important;
    height: auto !important;
    font-weight: 600 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

div[role="button"].google-login {
    width: 100% !important;
    margin: 8px 0 !important;
    border: 1px solid #ccc !important;
    border-radius: 18px !important;
    font-size: 16px !important;
    background-color: white !important;
    color: #757575 !important;
    box-shadow: 0 1px 3px rgba(0,0,0,0.08) !important;
    transition: background-color 0.3s, box-shadow 0.3s !important;
}

div[role="button"].google-login:hover {
    background-color: #f8f9fa !important;
    box-shadow: 0 2px 4px rgba(0,0,0,0.12) !important;
}

.do-login-text {
    margin-top: 0px;
    text-align: center;
    color: #666;
}

.signup-link {
    color: var(--primary-color, #007bff);
    cursor: pointer;
    text-decoration: underline;
}

.signup-link:hover {
    color: #0056b3;
}

@media (max-width: 1024px) {
    .wrapper {
        width: 50%;
    }
}

@media (max-width: 768px) {
    .wrapper {
        width: 70%;
    }
}

@media (max-width: 480px) {
    .wrapper {
        width: 90%;
    }
    
    .login-logo {
        width: 200px;
    }
}
