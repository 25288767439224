.app-button {
    background-color: var(--primary-color);
    color: white;
    padding: 15px 10px;
    border: none;
    width: 100%;
    border-radius: 50px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    margin: 20px 0;
    font-size: 1.1rem;
    font-weight: bold;
    transition: background-color 0.3s;
}