.file-size-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; 
}

.file-size-modal-content {
    background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 400px;
    width: 100%;
}

    .file-size-modal-close-button {
    background: linear-gradient(135deg, #ff4a00, #ff7a00);
    color: white;
    border: none;
    border-radius: 20px; 
    padding: 12px 24px; 
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s, filter 0.3s; 
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.file-size-modal-close-button:hover {
    background: linear-gradient(135deg, #e03a00, #ff4a00); 
    transform: scale(1.05); 
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4); 
    filter: brightness(1.1); 
}
