.drawer {
  height: 100vh;
  background-color: var(--background-color);
  border-right: 1px solid lightgray;
  /* position: fixed;
  top: 0;
  left: 0;
  z-index: 100; */
  /* transform: translateX(-100%); */

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
}


.drawer.closed{
  width: 7%;
  transition: width 0.3s ease-in-out;
}

.drawer.opened {
  width: 20%;
  transition: width 0.3s ease-in-out;
  /* transform: translateX(0); */
}

.drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  width: 90%;
  height: 5%;
}


.learn-button-container {
display: flex;
justify-content: center;
width: 100%;
}

.learn-button {
background-color: #ff4a00;
border: none;
border-radius: 30px;
color: #ffffff;
font-size: 14px;
font-weight: bold;
padding: 10px;
width: 100%;
cursor: pointer;
transition: background-color 0.3s ease;
}

.learn-button:hover {
background-color: #e64200;
}



.drawer-header.closed{
  margin-top: 10px;
  flex-direction: column;
  height: 15%;
  padding-left: 10px;
  
}

.menu-close{
  cursor: pointer;
}

.new-chat-button{
  cursor: pointer;
}

.drawer-body {
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.drawer-body.opened{
  padding: 20px;
}

.drawer-body.closed{
 padding: 0px;
}

.new-chat-round {
  background-color: lightgray;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease; 
}


.new-chat-round:hover {
  transform: scale(1.1); 
  background-color: #ccc; 
}

.chanakya-header-nav-closed {
font-size: 28px;
font-weight: 700;
line-height: 36.4px;
text-align: center;

}

.new-chat-text {
  cursor: pointer;
  display: inline-block; 
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.new-chat-text:hover {
  transform: scale(1.1); 
  opacity: 0.9; 
}



.chat-list {
  height: 77%;
  width: 100%;
  opacity: 1;
  display: block;
  transition: display 0.5s ease-in-out;
}

.chat-list.closed{
  display: none;
  opacity: 0;
  transition: display 0.5s ease-in-out;
}

.chat-item-holder {
  max-height: 70%;
  overflow-y: scroll;
  /* background-color: red; */
  padding-bottom: 40px; 

}

.chat-item {
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0px;
  cursor: pointer;
  transition: all 0.3s ease;
transform: translateZ(0);

}


.chat-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.chat-item.selected {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.1);
  transform: translateZ(10px) scale(1);
  animation: selectPop 0.3s ease-out;
}


@keyframes selectPop {
  0% {
    transform: translateZ(0) scale(1);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  50% {
    transform: translateZ(15px) scale(1.03);
  }
  100% {
    transform: translateZ(10px) scale(1.02);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
}


.chat-item:not(.selected):hover {
  background-color: rgba(0, 0, 0, 0.05);
  transform: translateZ(5px) scale(1.01);
}




.chat-item>span {
  margin-left: 10px;
}

.option-holder{
  width: 100%;
}

.option-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  width: 100%;
  cursor: pointer;
}



.option-item.closed{
  justify-content: center;
}

.hover-animation {
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.hover-animation:hover {
  transform: scale(1.1);
}


.option-item.opened{
  justify-content: space-between;
}

.option-item>.item>span {
  margin-left: 10px;
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: var(--primary-color);
  border-radius: 50%;
  margin-right: 10px;
}

.option-item.closed >.item>img{
  background-color: #F6F5F2;
  padding: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.show-more-less {
  background: linear-gradient(to bottom, rgba(255,255,255,0), var(--background-color) 50%);
  padding: 15px 0 10px;
  text-align: left;
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
  color: var(--primary-color);
  transition: all 0.3s ease;
}

.show-more-less:hover {
  color: var(--secondary-color);
  transform: translateY(-2px);
}

.show-more-less::before {
  content: '';
  position: absolute;
  top: 0; 
  left: 15%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  transition: all 0.3s ease;
}


.drawer-logo {
  width: 50%;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
.drawer.closed {
  width: 18%;
}


.drawer-logo {
  width: 40px;
  cursor: pointer;
}

.drawer.opened {
  width: 80%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

}



.drawer-header {
  padding: 10px;
}

.drawer-body.opened {
  padding: 10px;
}

.chat-item-holder {
  max-height: 60%;
}

.chat-item {
  font-size: 12px;
}

.option-item {
  padding: 8px 0;
}

.option-item.closed > .item > img {
  padding: 8px;
  width: 20px;
  height: 20px;
}

.show-more-less {
  font-size: 11px;
}
}