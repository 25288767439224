.premium-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.premium-modal-content {
  background: linear-gradient(145deg, #ffffff, #f8f9fa);
  border-radius: 20px;
  padding: 40px;
  position: relative;
  width: 90%;
  max-width: 800px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.premium-modal-content:hover {
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
}

.modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #6c757d;
  transition: color 0.3s ease;
}

.modal-close:hover {
  color: #343a40;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.modal-header h2 {
  font-size: 32px;
  margin: 0;
  color: #343a40;
}

.pro-premium {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-left: 15px;
  box-shadow: 0 4px 20px #ff4a00;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.pro-premium:hover {
  transform: scale(1.05);
}

.modal-description {
  text-align: center;
  margin: 0px 0;
  font-size: 18px;
  line-height: 1.6;
  color: #495057;
}

.modal-content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0px 94px;
}

.modal-image-container {
  flex: 0 0 40%;
  margin-right: 30px;
}

.modal-image {
  max-width: 100%;
  width: 225px;
  height: 221px;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
  margin-top: 16px;
}

.premium-features {
  flex: 0 0 55%;
}

.premium-features h3 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #343a40;
}

.premium-features ul {
  list-style-type: none;
  padding: 0;
}

.premium-features li {
  margin: 15px 0;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #495057;
}

.tick-icon {
  width: 24px;
  height: 24px;
  margin-right: 15px;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.05));
}

.subscription-plans {
  margin-top: 40px;
}

.subscription-plans h3 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #343a40;
}

.subscription-plans-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.planButton {
  flex: 1;
  max-width: 280px;
  background: #ffffff;
  border-radius: 15px;
  padding: 20px;
  border: 1px solid #e9ecef;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  background: linear-gradient(153.26deg, #FFFFFF 14.1%, #FF4A00 313.41%);
  position: relative;
}

.planButton:hover, .planButton.selected {
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  border-color: #ced4da;
}

.planButton.selected {
  border-color: #ff4a00;
}

.planContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.planTextContainer {
  text-align: center;
}

.planTitle {
  font-size: 20px;
  font-weight: bold;
  color: #343a40;
  margin-bottom: 5px;
}

.subscribeToday {
  font-size: 18px;
  color: #495057;
  margin-bottom: 5px;
}

.planSavings {
  font-size: 14px;
  color: #28a745;
}

.checkmarkContainer {
  position: absolute;
  top: 10px;
  right: 10px;
}

.checkmark {
  width: 24px;
  height: 24px;
}

.upgradeButton {
  background: linear-gradient(45deg, #ff4a00, #ff4a00);
  border: none;
  border-radius: 50px;
  color: white;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  margin: 30px auto 0;
  padding: 15px 30px;
  transition: all 0.3s ease;
  width: 569px; 
  max-width: 100%; 
  display: block;
  box-shadow: 0 5px 15px rgba(0, 123, 255, 0.3);
}

.upgradeButton:hover {
  background: linear-gradient(45deg, #ff4a00, #ff4a00);
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 123, 255, 0.4);
}

.upgradeButton:active {
  transform: translateY(1px);
  box-shadow: 0 3px 10px rgba(0, 123, 255, 0.3);
}

.upgradeButton.disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.error-message {
  color: #dc3545;
  text-align: center;
  margin-top: 10px;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .premium-modal-content {
    flex-direction: column;
    height: 90vh;
  }

  .modal-content-wrapper {
    flex-direction: column;
    margin: 0;
  }

  .modal-image-container {
    flex: 0 0 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }

  .premium-features {
    flex: 0 0 100%;
  }

  .subscription-plans-container {
    flex-direction: column;
    align-items: center;
  }

  .planButton {
    width: 100%;
    max-width: none;
  }

  .upgradeButton {
    width: 90%;
  }
}

.upgrade-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .upgrade-modal-content {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    width: 400px;
  }
  
  .upgrade-modal-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: black !important;
    background-color: transparent !important;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .upgrade-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .upgrade-modal-title {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  
  .upgrade-modal-description {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .upgrade-modal-input-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .upgrade-modal-input {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 0.5rem;
  }
  
  .upgrade-modal-input.error {
    border-color: red;
  }
  
  .upgrade-modal-input::placeholder {
    color: #999;
  }
  
  .upgrade-modal-input-error {
    color: red;
    font-size: 0.875rem;
    margin-bottom: 1rem;
    display: none;
  }
  
  .upgrade-modal-input-error.show {
    display: block;
  }
  
  .upgrade-modal-actions {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  
  .upgrade-modal-content button {
    background-color: #ff4a00;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 12px;
    font-size: 1rem;
    cursor: pointer;
    margin-right: 0.5rem;
  }
  
  .upgrade-modal-content button:last-child {
    background-color: #6c757d;
  }
  
  .upgrade-modal-content button:hover {
    opacity: 0.8;
  }
  
  .disabled {
    background-color: grey; 
    cursor: not-allowed; 
    opacity: 0.6;
  }


  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes slideIn {
    from { transform: translateY(-30px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
  