.message{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    max-width: 80%;
    margin-bottom: 20px;
}

.message.user{
    background-color: var(--primary-color);
    align-self: flex-end;
    max-width: 50%;
    border-radius: 10px;
    color: white;

}

.message.bot{
    background-color: var(--secondary-color);
    align-self: flex-start;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.message-content{
    margin-left: 10px;
    display: flex;
    flex-direction: column;
}

.generating-label{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: var(--primary-color);
    font-weight: bold;
}

.generating{
    margin-right: 10px;
    font-weight: bold;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--primary-color);
    animation: generating 1s infinite;
}

.btn-main {
    padding-left: 0;
  }
  
  .btn-box {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    justify-content: flex-start;
  }
  
  .bottom-btn {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    margin-bottom: 10px;
    border: none; 
    background: none; 
    transition: transform 0.2s ease, background 0.2s ease; 
  }
  
  .bottom-btn:hover {
    transform: scale(1.5); 
  }
  
  .bottom-btn:active {
    transform: scale(0.95); 
  }
  
  .stop-playing-btn {
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    border: none;
    background: none; 
    transition: transform 0.2s ease, background 0.2s ease;
  }
  
  .stop-playing-btn:hover {
    transform: scale(1.5);
  }
  
  .stop-playing-btn:active {
    transform: scale(0.95); 
  }
  
  .icon {
    width: 17px;
    height: 17px;
  }
  
@keyframes generating {
    0%{
        scale: 1;
    }
    50%{
        scale: 1.1;
    }
    100%{
        scale: 1;
    }
    
}

.chat-message {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px 20px;
  max-width: 70%;
  margin-bottom: 24px;
  border-radius: 18px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.user-message-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 70%;
  align-self: flex-end;
  margin-left: auto;
  margin-right: 20px;
}

.user-message {
  background-color: var(--primary-color);
  color: white;
  border-radius: 18px 18px 0 18px;
  padding: 4px 14px 1px 14px;
  max-width: 100%;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.user-message::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255,255,255,0.1), rgba(255,255,255,0));
  opacity: 0;
  transition: opacity 0.3s ease;
}

.user-message:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

.user-message:hover::before {
  opacity: 1;
}

.user-message-content {
  position: relative;
  z-index: 1;
}

.user-message-info {
  font-size: 0.75em;
  color: rgba(255, 255, 255, 0.7);
  text-align: right;
  margin-top: 6px;
  margin-right: 5px;
  font-style: italic;
}

.bot-message-container {
  align-self: flex-start;
  background-color: var(--secondary-color);
}

.user-message-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.bot-message-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.attachments-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 12px;
  width: 100%;
}

.attachment-wrapper {
  position: relative;
  border-radius: 12px;
  overflow: visible;
  transition: transform 0.2s ease;
  margin-bottom: 8px;
  max-width: 400px; 
}

.attachment-image {
  width: 70px;
  height: auto;
  max-height: 300px; 
  border-radius: 12px;
  object-fit: contain;
  cursor: pointer;
  transition: transform 0.3s ease, z-index 0.3s ease;
  z-index: 1;
}

.attachment-image:hover {
  transform: scale(1.3);
  z-index: 1000;
  position: relative;
}

.attachment-audio {
  width: 100%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--primary-color);
  border-radius: 25px;
}


.custom-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.spinner {
  width: 12px; 
  height: 12px; 
  border: 1.5px solid rgba(0, 0, 0, 0.1); 
  border-top: 1.5px solid var(--primary-color);
  border-right: 1.5px solid var(--primary-color); 
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes pulse {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.1); }
}

.custom-spinner::after {
  content: '';
  position: absolute;
  width: 18px; 
  height: 18px; 
  background-color: rgba(var(--primary-color-rgb), 0.1);
  border-radius: 50%;
  animation: pulse 1.5s ease-in-out infinite;
}

.user-message img {
  transition: transform 0.3s ease; 
  cursor: pointer; 
}

.user-message img:hover {
  transform: scale(1.2); 
  z-index: 1;
}
