.login-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--background-color);
}

.wrapper {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.login-logo {
    width: 250px;
    margin-bottom: 20px;
}

.forgot-title {
    color: var(--text-color, #333);
    margin-bottom: 10px;
    text-align: center;
}

.forgot-description {
    color: var(--text-secondary-color, #666);
    text-align: center;
    margin-bottom: 20px;
    font-size: 14px;
    max-width: 80%;
}

.input-container {
    width: 100%;
    margin-bottom: 20px;
}

.login-input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 18px;
    font-size: 16px;
    margin: 0;
}

.error-message {
    color: #ff4444;
    font-size: 14px;
    margin-top: 4px;
    display: block;
    text-align: left;
}

.login-button {
    width: 71% !important;
    margin-bottom: 16px;
}

.remember-pass-text {
    margin-top: 0px;
    text-align: center;
    color: #666;
}

.signup-link {
    color: var(--primary-color, #007bff);
    cursor: pointer;
    text-decoration: underline;
}

.signup-link:hover {
    color: #0056b3;
}


@media (max-width: 1024px) {
    .wrapper {
        width: 50%;
    }
}

@media (max-width: 768px) {
    .wrapper {
        width: 70%;
    }
}

@media (max-width: 480px) {
    .wrapper {
        width: 90%;
    }
    
    .login-logo {
        width: 200px;
    }
}
