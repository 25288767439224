@font-face {
  font-family: "Satoshi";
  src: url("../../../fonts/WEB/fonts/Satoshi-Regular.woff2") format("woff2"),
    url("../../../fonts/WEB/fonts/Satoshi-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.chat-screen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.input-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: sticky;
  bottom: 0;
  z-index: 10;
  padding: 10px 0;
  background-color: var(--input-background-color, #ffffff);
}

.input-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.upload-pin {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.chat-input {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 60%;
  max-height: 120px;
  border: 1px solid var(--input-border-color, #e0e0e0);
  border-radius: 25px;
  margin: 15px 10px;
  background-color: var(--input-background-color);
  transition: border-color 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.chat-input.with-attachment {
  padding-top: 110px;
}

.chat-input.with-audio-preview {
  padding-top: 72px;
}

.input-content {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 12px;
}

.attachment-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.attachment-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
}

.chat-textarea {
  margin-top: 10px;
  flex-grow: 1;
  font-family: "Satoshi", sans-serif;
  border: none;
  outline: none;
  resize: none;
  font-size: 16px;
  background-color: transparent;
  transition: color 0.3s ease;
  min-height: 28px;
  max-height: 100px;
  overflow-y: auto;
}

.chat-input.with-preview {
  padding-top: 120px; /* Increased padding when preview is present */
}

.chat-input:focus,
.chat-input:focus-within {
  outline: none;
  border-color: var(--input-focus-border-color, #ff4a00);
  box-shadow: 0 0 0 3px var(--input-focus-shadow-color, rgba(255, 74, 0, 0.15)),
              0 4px 8px rgba(0, 0, 0, 0.1);
 
  transform: translateY(-2px);
  transition: all 0.3s ease;
  animation: pulsate-glow 2s infinite;
}

@keyframes pulsate-glow {
  0% {
    box-shadow: 0 0 0 3px var(--input-focus-shadow-color, rgba(255, 74, 0, 0.15)),
                0 4px 8px rgba(0, 0, 0, 0.1);
  }
  50% {
    box-shadow: 0 0 0 5px var(--input-focus-shadow-color, rgba(255, 74, 0, 0.25)),
                0 6px 12px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 3px var(--input-focus-shadow-color, rgba(255, 74, 0, 0.15)),
                0 4px 8px rgba(0, 0, 0, 0.1);
  }
}

.chat-input:focus::after,
.chat-input:focus-within::after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 27px; 
  z-index: -1;
  opacity: 0.5;
  filter: blur(4px);
  
}



.chat-input::placeholder {
  color: var(--input-placeholder-color, #999);
}

.chat-textarea::placeholder {
  color: #999;

}

.chat-textarea:focus {
  color: var(--input-focus-text-color, #333);


}



.chat-send {
  width: 45px;
  height: 45px;
  border: none;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: 10px;
}

.chat-content {
  height: 85%;
}
.suggestion-holder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 25px;
  gap: 15px;
}

.suggestion {
  flex: 1;
  min-width: 0;
  border-radius: 25px;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  background-image: linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 15px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  position: relative;
}

.attachment-options {
  position: absolute;
  bottom: calc(86% + 1px);
  left: 15%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
  z-index: 1000;
  border: 1px solid #e0e0e0;
}

.attachment-option {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-bottom: 1px solid #f0f0f0;
}

.attachment-option:last-child {
  border-bottom: none;
}

.attachment-option:hover {
  background-color: #f8f9fa;
}

.attachment-option img {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  transition: transform 0.3s ease;
}

.attachment-option:hover img {
  transform: scale(1.1);
}

.attachment-option span {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}


@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.attachment-options {
  animation: fadeIn 0.3s ease-out;
}

.suggestion:hover {
  background-image: linear-gradient(135deg, #f8f9fa 0%, #e8eaed 100%);
  border-color: #d0d0d0;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  transform: translateY(-3px);
}

.suggestion:hover::after {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 80%
  );
  transform: scale(0);
  transition: transform 0.6s ease-out;
}

.suggestion:hover::after {
  transform: scale(1);
}

.suggestion:active {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
}

.suggestion > span {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.3s ease;
}

.suggestion:hover > span {
  color: #ff4a00;
}

.suggestion > i {
  font-size: 18px;
  color: #5f6368;
  transition: all 0.3s ease;
}

.suggestion:hover > i {
  color: #ff4a00;
  transform: scale(1.1) rotate(5deg);
}

.sources-main {
  padding-left: 55px;
  margin-bottom: 10px;
  perspective: 1000px;
}

@media (min-width: 769px) {
  .sources-main {
    margin-right: 205px;
  }
}

.source-title {
  font-size: 14px;
  color: #000000;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 10px;
  transform: translateZ(20px);
  transition: transform 0.3s ease;
}

.source-title:hover {
  transform: translateZ(30px) scale(1.05);
}

.sources-box {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
  transform-style: preserve-3d;
}

.favicon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  transition: transform 0.3s ease;
}

.source-btn {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: #ffffff;
  border-radius: 48px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateZ(10px);
}

.source-btn:hover {
  transform: translateZ(20px) scale(1.05);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.source-btn:hover .favicon {
  transform: rotate(360deg);
}

.source-text {
  color: #4125e2;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  transition: color 0.3s ease;
}

.source-btn:hover .source-text {
  color: #2c1a9e;
}

.search-text {
  font-size: 18px;
  color: #000000;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 10px;
  transform: translateZ(15px);
  transition: transform 0.3s ease;
}

.search-text:hover {
  transform: translateZ(25px) scale(1.03);
}


@keyframes floatAnimation {
  0% {
    transform: translateZ(10px);
  }
  50% {
    transform: translateZ(20px);
  }
  100% {
    transform: translateZ(10px);
  }
}

.sources-box {
  animation: floatAnimation 4s ease-in-out infinite;
}

@media (max-width: 768px) {
  .chat-screen {
    height: 100vh;
  }

  .chat-content.filled {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: scroll;
    width: 94% !important; 
  }

  .input-box {
    padding: 10px;
  }

  .drawer-screen-content {
    display: flex;
    flex: 1 1;
    height: 100%;
    width: 80%;
    transition: all 0.3s ease;
    background-color: white;
}


.chat-content.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 65%;
  text-align: center;
}

  .input-wrapper {
    width: 100%;
    justify-content: center;  
  }

  .chat-input {
    width: calc(100% - 60px); 
    margin: 10px 5px;
    font-size: 14px;
    padding: 10px 15px 10px 35px;
  }

  .chat-send {
    width: 40px;
    height: 40px;
  }

  .suggestion-holder {
    flex-direction: column;
    align-items: stretch;
    margin-top: 15px;
    gap: 10px;
    padding: 0 10px;
  }

  .suggestion {
    width: 100%;
    margin-bottom: 5px;
    padding: 10px;
  }

  .suggestion > span {
    font-size: 12px;
  }

  .sources-main {
    padding-left: 20px;
    margin-right: 20px;
  }

  .source-title,
  .search-text {
    font-size: 14px;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(26, 115, 232, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(26, 115, 232, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(26, 115, 232, 0);
  }
}

.suggestion:focus {
  outline: none;
  animation: pulse 1.5s infinite;
} 



.chat-content.filled {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
  width: 70%;
}




.chat-content.filled {
  overflow-y: auto;
  padding: 0 15px;
}

.chat-content.filled::-webkit-scrollbar {
  width: 8px;

  border-radius: 10px;
  background-color: #f5f5f5;
}

.chat-content.filled::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: lightgray;
  border: none;
}

.chat-content.filled::-webkit-scrollbar-thumb:hover {
  background-color: gray;
}

.chat-content.filled::-webkit-scrollbar-track-piece:start {
  margin-top: -20px;
}

.chat-content.filled::-webkit-scrollbar-button:single-button:start {
  display: none;
}

.chat-content.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}

.chat-content.empty h1 {
  color: var(--primary-color);
  margin: 0;
}

.chat-content.empty h2 {
  color: black;
  font-weight: normal;
}

.upload-options {
  position: absolute;
  bottom: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.upload-option {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  border: none;
  background: none;
}

.upload-option img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.image-preview {
  position: absolute;
  top: 10px;
  left: 13px;
  width: 160px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  z-index: 2;
  padding: 2px;
  border: 1px solid rgba(254, 75, 1, 0.28);
  background-color: #ffffff;
}

.image-preview img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 6px;
}

.image-preview button {
  position: absolute;
  top: 2px;
  right: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 12px;
  line-height: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.image-preview button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

@media (max-width: 768px) {
  .image-preview {
    height: 80px;
  }
  
  .chat-input {
    width: calc(100% - 60px);
    font-size: 14px;
    padding: 10px 15px;
  }

  .chat-input.with-preview {
    padding-top: 100px; /* Adjusted padding for mobile */
  }
}

.attachment-preview {
  position: absolute;
  top: 50%;
  left: 70px; 
  transform: translateY(-50%);
  width: 180px; 
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--input-background-color);
  border: 1px solid var(--input-border-color, #e0e0e0);
  border-radius: 15px 0 0 15px;
  overflow: hidden;
  z-index: 2;
}

.attachment-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px 0 0 15px;
}

.attachment-preview .audio-preview {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px;
}

.attachment-preview .audio-waveform {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.attachment-preview .audio-waveform .waveform-bar {
  width: 2px;
  background-color: #FF4A00;
  transition: height 0.2s ease;
}

.attachment-preview .audio-info {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.attachment-preview .audio-icon {
  font-size: 14px;
  color: #FF4A00;
  margin-right: 6px;
}

.attachment-preview .audio-filename {
  font-size: 10px;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
}

.attachment-preview .remove-attachment {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.attachment-preview .remove-attachment:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

@media (max-width: 768px) {
  .attachment-preview {
    width: 140px;
    height: 60px; 
    left: 50px; 
  }
  
  .chat-input {
    width: calc(100% - 60px);
    font-size: 14px;
    padding: 10px 15px 10px 35px;
  }

  .chat-input.with-preview {
    padding-left: 200px; 
  }

  .attachment-preview .audio-waveform {
    height: 20px;
  }

  .attachment-preview .audio-icon {
    font-size: 12px;
  }

  .attachment-preview .audio-filename {
    font-size: 8px;
    max-width: 80px;
  }
}

.audio-preview {
  position: absolute;
  top: 10px;
  left: 13px;
  width: calc(100% - 26px);
  max-width: 230px; 
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--input-background-color, #f5f5f5);
  border-radius: 15px;
  overflow: hidden;
  z-index: 2;
  padding: 0 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--input-border-color, #e0e0e0);
}

.audio-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  min-width: 0;
  margin-right: 10px;
}

.audio-icon {
  flex-shrink: 0;
  font-size: 20px;
  margin-right: 10px;
}

.audio-filename {
  font-size: 14px;
  color: var(--text-color, #333);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  max-width: calc(100% - 70px); 
  font-weight: 500;
}

.remove-attachment {
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.1);
  color: var(--text-color, #333);
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.remove-attachment:hover {
  background-color: rgba(0, 0, 0, 0.2);
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .audio-preview {
    height: 50px;
    padding: 0 8px;
    max-width: 220px; 
  }

  .audio-icon {
    font-size: 18px;
    margin-right: 8px;
  }

  .audio-filename {
    font-size: 12px;
    max-width: calc(100% - 60px);
  }

  .remove-attachment {
    width: 24px;
    height: 24px;
    font-size: 14px;
  }
}

.get-started-btn {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  width: 300px;
  color: white;
  background-color: #FF4A00;
  border: none;
  border-radius: 30px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.get-started-btn:hover {
  background-color: #FF8A50;
}
