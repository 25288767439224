.conversation-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .conversation-modal-content {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 90%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    color: #666;
  }
  
  .alert-icon {
    color: #ff6b6b;
    margin-bottom: 20px;
  }
  
  .title {
    font-size: 22px;
    color: #333;
    text-align: center;
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .messageLimit {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
  }


  
  .action-button {
    background-color: rgba(255, 74, 0, 1);
    color: white;
    border: none;
    padding: 10px 20px;
    width: 80%;
    font-size: 16px;
    cursor: pointer;
    border-radius: 30px;
    transition: background-color 0.3s ease;
  }
  
  .action-button:hover {
    background-color: rgba(255, 122, 68, 1); 
  }
  