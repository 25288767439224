.login-screen{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--background-color);
}

.login-wrapper{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-logo{
   width: 250px;
   margin-bottom: 20px;
}

.login-input {
    width: 100%;
    padding: 12px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 18px;
    font-size: 16px;

}

.divider {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #ccc;
  line-height: 0.1em;
  margin: 15px 0;
}



.divider span {
  background: var(--background-color);
  padding: 0 10px;
  color: #666;
}

.google-btn {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 8px !important;
    background-color: white !important;
    color: #757575 !important;
    border: 1px solid #dadce0 !important;
    box-shadow: 0 1px 3px rgba(0,0,0,0.08) !important;
    transition: background-color 0.3s, box-shadow 0.3s !important;
}

.google-btn:hover {
    background-color: #f8f9fa !important;
    box-shadow: 0 2px 4px rgba(0,0,0,0.12) !important;
}

.google-btn svg {
  margin-right: 8px;
}

.google-login-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: white;
  color: #757575;
  border: 1px solid #dadce0;
}

.google-login-button:hover {
  background-color: #f8f9fa;
}

.google-login-button svg {
  margin-right: 8px;
}

div[role="button"].google-login {
    width: 100% !important;
    margin: 8px 0 !important;
    border: 1px solid #ccc !important;
    border-radius: 18px !important;
    font-size: 16px !important;
    background-color: white !important;
    color: #757575 !important;
    box-shadow: 0 1px 3px rgba(0,0,0,0.08) !important;
    transition: background-color 0.3s, box-shadow 0.3s !important;
}

div[role="button"].google-login:hover {
    background-color: #f8f9fa !important;
    box-shadow: 0 2px 4px rgba(0,0,0,0.12) !important;
}

#googleButton,
iframe[role="presentation"],
div[class*="google-login"],
div[style*="position: relative"] {
    width: 100% !important;
    border-radius: 24px !important;
    padding: 20px 32px !important;
    font-size: 18px !important;
    height: auto !important;
    font-weight: 600 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.social-login {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 45px;
    transform: scale(1.2);
    margin-right: 76px;
    margin-bottom: 20px;
}

.input-container {
    width: 100%;
    margin-bottom: 20px;
}

.login-button {
    width: 71% !important;
}

.error-message {
    color: #ff4444;
    font-size: 14px;
    margin-top: 4px;
    display: block;
    text-align: left;
}

.login-input {
    margin: 0;  
}

.forgot-password {
    color: var(--primary-color, #007bff);
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    margin-top: 8px;
    align-self: flex-end;
    margin-bottom: 16px;
}

.forgot-password:hover {
    opacity: 0.8;
}

.signup-text {
  margin-top: 20px;
  text-align: center;
  color: #666;
  width: 100%;
}

.signup-link {
  color: var(--primary-color, #007bff);
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
}

.signup-link:hover {
  color: #0056b3;
}
