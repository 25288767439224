.error-simulator {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 100vh;


}

.sim-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

}

.sim-input-section{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    border-right: 1px solid #e0e0e0;
    padding-bottom: 40px;
}

.sim-output-section{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;    
    height: 100%;
}

.sim-input-holder{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.sim-case{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin-top: 10px;
}

.sim-case-desc{
    width: 60%;
}

.sim-case-desc>h3{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px;
}

.sim-case-desc>p{
    font-size: 14px;
    color: #666;
    margin-top: 10px;
}

.sim-try{
    border: none;
    background-color: var(--primary-color);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.sim-output-holder{
    width: 90%;
}